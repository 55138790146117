<template>
  <div class="AppMain d-flex flex-wrap">
    <!--MENU LATERAL IZQUIERDO-->
    <div class="LateralMenuContent HideOnMovil">
      <MenuGeneral />
    </div>

    <!--MENU PARA DISPOSITIVOS MOVILES-->
    <div class="SuperiorMenuContent ShowFlexOnMovil">
      <MenuGeneralMovil />
    </div>

    <!--CONTENEDOR GENERAL-->
    <div class="GeneralContent FullOnMovil pl-10 pr-10">
      <!--TITULO DE LA PÁGINA-->
      <TitlePage title="Subscription" />

      <!--BARRA DE NAVEGACIÓN DE SUBSCRIPTION-->
      <BarNav link1="" link2="lnkActive" />

      <!--PASOS DE REGISTRO-->
      <Stepper step3="StepActive" stepMovil1="" stepMovil2="Quotation" stepMovil3="" />

      <!--ESPACIO EN BLANCO-->
      <WhiteSpace></WhiteSpace>
    </div>
  </div>
</template>
<script>
import TitlePage from '@/components/TitlePage.vue';
import MenuGeneral from '@/components/Menu/MenuGeneral.vue';
import MenuGeneralMovil from '@/components/Menu/MenuGeneralMovil.vue';
import BarNav from '@/components/subscription/BarNav.vue';
import Stepper from '@/components/subscription/submission/Stepper';

import WhiteSpace from '@/components/WhiteSpace.vue';

//MIXINS
import { stateExpansiveManager } from '@/mixins/subscription.js';
import { mapGetters } from 'vuex';
export default {
  name: 'Bound',
  mixins: [stateExpansiveManager],
  components: {
    TitlePage,
    MenuGeneral,
    MenuGeneralMovil,
    BarNav,
    Stepper,
    WhiteSpace,
  },
  computed: {
    ...mapGetters(['subscription_id']),
  },
  methods: {},
};
</script>
<style lang="less" scoped>
//CONTENEDOR DE QUOTATION
@import '~@/assets/style/AccordionStyle.less';
</style>
